import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { post } from "@rails/request.js"

// Connects to data-controller="tierlist"
export default class extends Controller {

  static values = {
    interactive: Boolean
  }

  connect() {
    if(!this.interactiveValue) {
      return;
    }

    console.log("CONNECTED")

    const bgsElement = document.getElementById('tierlist-bgs');
    const tier1 = document.getElementById('tier-1');
    const tier2 = document.getElementById('tier-2');
    const tier3 = document.getElementById('tier-3');
    const tier4 = document.getElementById('tier-4');
    const tier5 = document.getElementById('tier-5');
    const tier6 = document.getElementById('tier-6');
    const tier7 = document.getElementById('tier-7');

    const putOptions = ['bgs', 'tier-1', 'tier-2', 'tier-3', 'tier-4', 'tier-5', 'tier-6', 'tier-7'];

    Sortable.create(bgsElement, {
      animation: 250,
      group: {
        name: 'bgs',
        put: putOptions
      },
      // onAdd: this.end.bind(this)
    });

    Sortable.create(tier1, {
      animation: 250,
      group: {
        name: 'tier-1',
        put: putOptions
      },
      onAdd: this.onAdd.bind(this),
      onEnd: this.onEnd.bind(this),
    });

    Sortable.create(tier2, {
      animation: 250,
      group: {
        name: 'tier-2',
        put: putOptions
      },
      onAdd: this.onAdd.bind(this),
      onEnd: this.onEnd.bind(this),

    });

    if (tier3) {
      Sortable.create(tier3, {
        animation: 250,
        group: {
          name: 'tier-3',
          put: putOptions
        },
        onAdd: this.onAdd.bind(this),
        onEnd: this.onEnd.bind(this),

      });
    }

    if (tier4) {
      Sortable.create(tier4, {
        animation: 250,
        group: {
          name: 'tier-4',
          put: putOptions
        },
        onAdd: this.onAdd.bind(this),
        onEnd: this.onEnd.bind(this),

      });
    }

    if (tier5) {
      Sortable.create(tier5, {
        animation: 250,
        group: {
          name: 'tier-5',
          put: putOptions
        },
        onAdd: this.onAdd.bind(this),
        onEnd: this.onEnd.bind(this),

      });
    }

    if (tier6) {
      Sortable.create(tier6, {
        animation: 250,
        group: {
          name: 'tier-6',
          put: putOptions
        },
        onAdd: this.onAdd.bind(this),
        onEnd: this.onEnd.bind(this),

      });
    }

    if (tier7) {
      Sortable.create(tier7, {
        animation: 250,
        group: {
          name: 'tier-7',
          put: putOptions
        },
        onAdd: this.onAdd.bind(this),
        onEnd: this.onEnd.bind(this),

      });
    }
  }


  onAdd(e) {
    console.log("AAAAA")
    console.log(e.newIndex);
    console.log(e.item.dataset.tierlistBgId)
    console.log(e.item.parentElement)
    console.log(e.item.parentElement.dataset.tierlistUrl)

    if (e.item.parentElement.dataset.tierlistUrl) {
      post(e.item.parentElement.dataset.tierlistUrl, {
        body: JSON.stringify({ bg_id: e.item.dataset.tierlistBgId, row_order_position: e.newIndex }),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {
        console.log(response)
      })
    }
  }

  onEnd(e) {
    console.log("BBBB")
    console.log(e.newIndex);
    console.log(e.item.dataset.tierlistBgId)

    if (e.item.parentElement.dataset.tierlistUrl) {
      post(e.item.parentElement.dataset.tierlistUrl, {
        body: JSON.stringify({ bg_id: e.item.dataset.tierlistBgId, row_order_position: e.newIndex, only_reorder: true }),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {
        console.log(response)
      })

    }
  }
}
