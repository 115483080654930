// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



import 'tom-select/dist/css/tom-select.css'

import { Turbo } from '@hotwired/turbo-rails'
import { Application } from '@hotwired/stimulus'
import Dropdown from 'stimulus-dropdown'
import ReadMore from 'stimulus-read-more'
import Reveal from 'stimulus-reveal-controller'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
window.toBlob = toBlob;
window.toJpeg = toJpeg;

import SelectController from 'controllers/ts/select_controller'
import FormController from 'controllers/form_controller'
import SlideshowController from 'controllers/slideshow_controller'
import FilterableFormController from 'controllers/filterable_form_controller'
import SearchFormController from 'controllers/search_form_controller'
import ModalController from 'controllers/modal_controller'
import ComboboxController from 'controllers/combobox_controller'
import SelectSearchBoxController from 'controllers/select_search_box_controller'
import UserCollectionController from 'controllers/user_collection_controller'
import BannerTrackingController from 'controllers/banner_tracking_controller'
import LandingController from 'controllers/landing_controller'
import LightboxController from 'controllers/lightbox_controller'
import ShowHideController from 'controllers/show_hide_controller'
import TierlistController from "controllers/tierlist_controller"


import Rails from '@rails/ujs'

import 'channels'

import 'stylesheets/application'
import 'photoswipe/style.css';
import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css'


Turbo.session.drive = false // Disable by default

window.Rails = Rails
Rails.start()

// import '@fortawesome/fontawesome-free/js/all'
require.context('../images', true)
// require.context('../images/partners', true)
// const imagePath = (name) => images(name, true)
window.Stimulus = Application.start()
Stimulus.register('form', FormController)
Stimulus.register('slideshow', SlideshowController)
Stimulus.register('filterable-form', FilterableFormController)
Stimulus.register('search-form', SearchFormController)
Stimulus.register('modal', ModalController)
Stimulus.register('combobox', ComboboxController)
Stimulus.register('ts--select', SelectController)
Stimulus.register('dropdown', Dropdown)
Stimulus.register('read-more', ReadMore)
Stimulus.register('reveal', Reveal)
Stimulus.register('select-search-box', SelectSearchBoxController)
Stimulus.register('user-collection', UserCollectionController)
Stimulus.register('landing', LandingController)
Stimulus.register('lightbox', LightboxController)
Stimulus.register('banner-tracking', BannerTrackingController)
Stimulus.register('show-hide', ShowHideController)
Stimulus.register('form', FormController)
Stimulus.register("tierlist", TierlistController)
